import React, { FC, useEffect, useState } from "react";
import { Button } from "../Button";

import SpecialtyDropdown from "../Forms/SpecialtyDropdown";
import DesignationDropdown from "../Forms/DesignationDropdown";
import { eventTracker } from "../../tracker";

type DesAndSpecType = {
  onYes: () => void;
};

const DesignationAndSpecialty: FC<DesAndSpecType> = ({ onYes }) => {
  const [desgSelected, setDesgSelected] = useState(false);
  const [specSelected, setSpecSelected] = useState(false);

  const buttonDisabled = !desgSelected || !specSelected;

  useEffect(() => {
    eventTracker({
      eventLabel: "Step 1/4 - Designation and Specialty",
      eventAction: "Viewed Step",
      eventCategory: "Request KOL Form",
    });
  }, []);

  return (
    <>
      <h3
        style={{
          marginBottom: "-5px",
        }}
      >
        What is your designation and specialty?
      </h3>
      <DesignationDropdown
        onChange={() => {
          setDesgSelected(true);
        }}
      />
      <SpecialtyDropdown
        onChange={() => {
          setSpecSelected(true);
        }}
      />
      <Button
        white
        text="Next"
        onClick={onYes}
        disabled={buttonDisabled}
        analytics={{
          subject: "Meeting Request",
          label: "Request KOL - Step 2/4 - Next",
          action: "Clicked",
          category: "Internal Links",
        }}
      />
    </>
  );
};

export default DesignationAndSpecialty;
