import React, { FC } from "react";
import SelectInput from "../Select";
import { tracker } from "../../../tracker";

const designationOptions = [
  { value: "MD", label: "MD" },
  { value: "DO", label: "DO" },
  { value: "Pharmacist", label: "Pharmacist" },
  { value: "Doctor of Pharmacy", label: "Doctor of Pharmacy" },
  { value: "Nurse Practitioner", label: "Nurse Practitioner" },
  { value: "Physician Assistant", label: "Physician Assistant" },
  { value: "Resident", label: "Resident" },
  { value: "Other", label: "Other" },
];

const DesignationDropdown: FC<{ onChange?: () => void }> = ({ onChange }) => {
  return (
    <SelectInput
      label="Designation"
      name="designation"
      placeHolder="Select Designation"
      errorMessage="Please select a designation."
      options={designationOptions}
      onChange={(item) => {
        tracker({
          event: "gbt.event",
          eventCategory: "Request KOL Form",
          userDesignation: item.value,
          eventSubject: "Meeting Request",
        });
        onChange();
      }}
    />
  );
};

export default DesignationDropdown;
