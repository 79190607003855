import React, { FC } from "react";
import { Button } from "../Button";

type GetStartedType = { onYes: () => void };

const GetStarted: FC<GetStartedType> = ({ onYes }) => {
  return (
    <div>
      <h3>Great, you qualify!</h3>
      <p
        className="schedule-card__content no-padding"
        style={{ marginLeft: "-10px", marginRight: " -10px" }}
      >
        <strong>We’d like to offer you an online session.</strong>
        <br /> Please make sure you have your NPI number and current schedule
        handy while you fill out the request form.
      </p>
      <Button
        text="Get Started"
        onClick={onYes}
        analytics={{
          subject: "Meeting Request",
          label: "Request KOL - Step 2/4 - Next",
          action: "Clicked",
          category: "Internal Links",
        }}
      />
    </div>
  );
};

export default GetStarted;
