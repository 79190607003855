import React, { FC, useEffect } from "react";
import marked from "marked";
import { Kol } from "../../types";

import exitIcon from "../../images/exit-icon.svg";

import "./styles.scss";

type kolModal = {
  kol: Kol;
  onExit: () => void;
};

const KolModalEl: FC<kolModal> = ({ kol, onExit }) => {
  useEffect(() => {
    document.addEventListener("keydown", handlExitModal);
    document.documentElement.style.overflow = "hidden";
    return () => {
      document.removeEventListener("keydown", handlExitModal);
      document.documentElement.style.overflow = "";
    };
  }, []);

  const handlExitModal = (evt) => {
    evt = evt || window.event;
    let isEscape = false;
    if ("key" in evt) {
      isEscape = evt.key === "Escape" || evt.key === "Esc";
    } else {
      isEscape = evt.keyCode === 27;
    }
    if (isEscape) {
      onExit();
    }
  };

  return (
    <div className="kol-modal--background" onClick={onExit}>
      <div
        className="kol-modal--wrapper-border"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="kol-modal">
          <span className="kol-modal--exit" onClick={onExit}>
            <img src={exitIcon} alt="exit" />
          </span>
          <div className="kol-modal--image">
            <img
              src={kol.photo.localFile.publicURL}
              alt={kol.photo.alternativeText}
            />
          </div>
          <div className="kol-modal--information">
            <div className="kol-box__name mb1">
              {kol.firstName} {kol.lastName}, {kol.designation}
            </div>
            <div className="kol-box__title mb1">{kol.title}</div>
            <div className="kol-box__location mb1">{kol.location}</div>
            <div className="kol-modal--hr"></div>
            <p dangerouslySetInnerHTML={{ __html: marked(kol.bio) }}></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KolModalEl;
