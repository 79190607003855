import React, { FC, PropsWithChildren, ReactNode, useState } from "react";
import { eventTracker } from "../../tracker";
import { Kol } from "../../types";

import "./kol-box.scss";

type KolBoxProps = Kol;

type KolBoxComponent = FC<KolBoxProps>;

export const KolBox: KolBoxComponent = ({
  firstName,
  lastName,
  designation,
  title,
  location,
  photo,
  bio,
  onSetKolModal,
}) => {
  return (
    <div className="kol-box">
      {photo && (
        <img
          className="kol-box__headshot"
          alt={
            photo.alternativeText ||
            `Photo of ${firstName} ${lastName}, ${designation}`
          }
          src={photo.localFile.publicURL}
        />
      )}
      <div className="kol-box__content">
        <div className="kol-box__name mb1">
          {firstName} {lastName}, {designation}
        </div>
        <div className="kol-box__title mb1">{title}</div>
        <div className="kol-box__location">{location}</div>
        <div className="kol-box__bio">{bio}</div>
        <a
          href="#"
          className="kol-box__bio-link"
          onClick={(e) => {
            e.preventDefault();
            onSetKolModal();
            eventTracker({
              eventLabel: `Read Bio - ${firstName} ${lastName}`,
              eventAction: "Opened",
              eventCategory: "Pop-ups",
            });
          }}
        >
          Read Bio
        </a>
      </div>
    </div>
  );
};

type KolBoxGridComponent = FC<PropsWithChildren<{}>>;

export const KolBoxGrid: KolBoxGridComponent = ({ children }) => (
  <div className="flex flex--four kol-box-grid">
    {children &&
      (children as ReactNode[]).map((child, index) => (
        <div className="flex__cell kol-box-grid__li" key={index}>
          {child}
        </div>
      ))}
  </div>
);
