import React, { useState } from "react";
import { graphql, Link, PageProps } from "gatsby";

import { Page } from "../components/Page";
import { TinyNav } from "../components/TinyNav";
import { KolBox, KolBoxGrid } from "../components/KolBox";
import { OneColumn, TwoColumn } from "../components/Flexbox";
import { BenefitsList, BenefitsListItem } from "../components/BenefitsList";
import ScheduleCard from "../components/ScheduleCard";
import KolModalEl from "../components/KolModal";

import benefitIconChooseDrUrl from "../images/home/benefits-choose-physician.svg";
import benefitIconLearnPreferredUrl from "../images/home/benefits-learn-preferred.svg";
import benefitIconOnYourTimeUrl from "../images/home/benefits-on-your-time.svg";
import benefitIconPersonalizedUrl from "../images/home/benefits-personalized-sessions.svg";

import { FormContextProvider } from "../components/Forms/FormsContext";

import { Kol, StrapiQueryData } from "../types";

import { pageTracker } from "../tracker";

import "./styles.scss";

import "@fontsource/raleway/400.css";
import "@fontsource/raleway/700.css";
import "@fontsource/open-sans";

import "@fontsource/open-sans/700.css";

export const query = graphql`
  query MyQuery {
    allStrapiKol(sort: { fields: lastName, order: ASC }) {
      nodes {
        bio
        firstName
        lastName
        designation
        title
        location
        photo {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;

const IndexPage: React.FC<PageProps<StrapiQueryData>> = ({
  data,
  location,
}) => {
  const [kolModal, setKolModal] = useState<Kol>(null);
  const kolData = data.allStrapiKol.nodes;

  React.useEffect(() => {
    pageTracker({
      pageTitle: "Request KOL",
      pageSubject: "Meeting Request",
    });
  }, []);

  return (
    <Page>
      {kolModal && (
        <KolModalEl kol={kolModal} onExit={() => setKolModal(null)} />
      )}
      <OneColumn className="home-page__header">
        <h1>
          Learn more about Oxbryta<sup>&reg;</sup> from SCD medical experts who
          treat patients with sickle cell disease
        </h1>
        <p className="home-page__sub-header">
          Are you a practicing healthcare professional in the United States?
          Sign up for a complimentary 30-60 minute online session with one of
          your peers.
        </p>
      </OneColumn>
      <TwoColumn>
        <BenefitsList>
          <BenefitsListItem
            title="Personalized sessions"
            image={benefitIconPersonalizedUrl}
            text="One-on-one meetings that are just for you."
          ></BenefitsListItem>
          <BenefitsListItem
            title="On your time"
            image={benefitIconOnYourTimeUrl}
            text="Choose a date and time that fits your schedule."
          ></BenefitsListItem>
          <BenefitsListItem
            title="You choose the healthcare professional"
            image={benefitIconChooseDrUrl}
            text="Select from our panel of medical experts."
          ></BenefitsListItem>
          <BenefitsListItem
            title="You pick the Oxbryta session topic"
            image={benefitIconLearnPreferredUrl}
            text="Choose a presentation and get your Oxbryta questions answered."
          ></BenefitsListItem>
        </BenefitsList>

        <>
          <FormContextProvider>
            <ScheduleCard />
          </FormContextProvider>

          <TinyNav>
            <h4>Want to learn more about Oxbryta right now?</h4>
            <a
              data-analytics-event-action="Followed"
              data-analytics-event-subject="Meeting Request"
              data-analytics-event-category="External Sites"
              data-analytics-event-label="https://hcp.oxbryta.com/mechanism-of-action"
              href="https://hcp.oxbryta.com/mechanism-of-action"
            >
              Mechanism&nbsp;of&nbsp;Action
            </a>
            <span className="link__separator ">|</span>
            <a
              href="https://hcp.oxbryta.com/safety"
              data-analytics-event-action="Followed"
              data-analytics-event-subject="Meeting Request"
              data-analytics-event-category="External Sites"
              data-analytics-event-label="https://hcp.oxbryta.com/safety"
            >
              Safety
            </a>
            <span className="link__separator ">|</span>
            <a
              href="https://hcp.oxbryta.com/clinical-data"
              data-analytics-event-action="Followed"
              data-analytics-event-subject="Meeting Request"
              data-analytics-event-category="External Sites"
              data-analytics-event-label="https://hcp.oxbryta.com/clinical-data"
            >
              Efficacy
            </a>
            <span className="link__separator ">|</span>
            <a
              href="https://hcp.oxbryta.com/expert-videos"
              data-analytics-event-action="Followed"
              data-analytics-event-subject="Meeting Request"
              data-analytics-event-category="External Sites"
              data-analytics-event-label="https://hcp.oxbryta.com/expert-videos"
            >
              Expert&nbsp;Videos
            </a>
          </TinyNav>
        </>
      </TwoColumn>
      <div className="kol-box__container">
        <h2 className="kol-box__header">Meet our medical experts</h2>
        <p className="body-text disclaimer">
          <em>
            <strong>
              Disclosure: All healthcare professionals are paid by Global Blood
              Therapeutics for participating in these online sessions.
            </strong>
          </em>
        </p>
        <KolBoxGrid>
          {kolData &&
            kolData.map((kol: Kol) => (
              <KolBox
                onSetKolModal={() => {
                  setKolModal(kol);
                }}
                key={`${kol.firstName} ${kol.lastName}`}
                {...kol}
              />
            ))}
        </KolBoxGrid>
      </div>
    </Page>
  );
};

export default IndexPage;
