import React, { FC, PropsWithChildren } from "react";

import "./card.scss";

type CardProps = PropsWithChildren<{
  brand?: "oxbryta";
}>;

export const Card: FC<CardProps> = ({ children, brand }) => {
  const showHeader = !!brand;

  const classes = [
    "card",
    showHeader && "card--has-header",
    `card--brand-${brand || "none"}`,
  ]
    .filter((x) => x)
    .join(" ");

  const headerClasses = [
    "card__header",
    `card__header--brand-${brand || "none"}`,
  ]
    .filter((x) => x)
    .join(" ");

  return (
    <div className={classes}>
      {showHeader && <header className={headerClasses}></header>}
      <div className="card__body">{children}</div>
    </div>
  );
};
