import React, { FC } from "react";
import { Button } from "../Button";

const NotEligable: FC = () => {
  return (
    <div>
      <h3>Sorry, you're not eligable.</h3>
      <p
        className="schedule-card__content no-padding"
        style={{ margin: "30px -26px" }}
      >
        Thank you for your interest in booking a session. Sessions are intended
        for US healthcare professionals who currently treat sickle cell disease.
        Reach out to your GBT Sickle Cell Therapeutic Specialist if you do not
        have an NPI number. You can also visit{" "}
        <a
          href="www.gbtmedinfo.com"
          data-analytics-event-action="Followed"
          data-analytics-event-subject="Meeting Request"
          data-analytics-event-category="External Sites"
          data-analytics-event-label="www.gbtmedinfo.com"
        >
          www.gbtmedinfo.com
        </a>{" "}
        or call us at <a href="tel:1-833-428-4968">1-833-428-4968</a> with any
        questions.
      </p>
      <Button
        white
        link="https://gbtmedinfo.com/Home/Portal"
        text="Go to Med Info"
        analytics={{
          subject: "Meeting Request",
          action: "Followed",
          category: "External Sites",
        }}
      />
    </div>
  );
};

export default NotEligable;
