import React, { FC, useContext } from "react";

import QuestionSection from "./QuestionSection";
import NotEligable from "./NotEligable";
import GetStarted from "./GetStarted";

import { Card } from "../Card";
import DesignationAndSpecialty from "./DesignationAndSpecialty";

import "./styles.scss";
import { FormsContext, FormsContextProviderType } from "../Forms/FormsContext";

const ScheduleCard: FC = () => {
  const [section, setSection] = React.useState(0);

  const formContext: FormsContextProviderType = useContext(FormsContext);

  return (
    <div className="schedule-card">
      <Card brand="oxbryta">
        {section === 0 && (
          <QuestionSection
            onNo={() => setSection(1)}
            onYes={() => setSection(2)}
          />
        )}
        {section === 1 && <NotEligable />}
        {section === 2 && (
          <DesignationAndSpecialty onYes={() => setSection(3)} />
        )}
        {section === 3 && <GetStarted onYes={() => formContext.setForm(0)} />}
      </Card>
    </div>
  );
};

export default ScheduleCard;
