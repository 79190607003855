import React, { FC } from "react";
import SelectInput from "../Select";
import { tracker } from "../../../tracker";

const specialtyOptions = [
  { value: "Hematology/Oncology", label: "Hematology/Oncology" },
  { value: "Pediatric Hematology", label: "Pediatric Hematology" },
  {
    value: "Family Medicine",
    label: "Family Medicine",
  },
  { value: "Pediatric Medicine", label: "Pediatric Medicine" },
  { value: "Internal Medicine", label: "Internal Medicine" },
  { value: "Emergency Medicine", label: "Emergency Medicine" },
  { value: "Other", label: "Other" },
];

const SpecialtyDropdown: FC<{ onChange?: () => void }> = ({ onChange }) => {
  return (
    <SelectInput
      label="Speciality"
      name="specialty"
      placeHolder="Select Specialty"
      options={specialtyOptions}
      errorMessage="Please select a specialty."
      onChange={(item) => {
        tracker({
          event: "gbt.event",
          eventCategory: "Request KOL Form",
          userSpecialty: item.value,
          eventSubject: "Meeting Request",
        });
        onChange();
      }}
    />
  );
};

export default SpecialtyDropdown;
