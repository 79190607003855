import React, { FC } from "react";
import { Button } from "../Button";

type QuestionSectionType = {
  onNo: () => void;
  onYes: () => void;
};

const QuestionSection: FC<QuestionSectionType> = ({ onNo, onYes }) => {
  return (
    <>
      <h2>Ready to schedule a session?</h2>
      <p className="schedule-card__content">
        Are you a US healthcare professional with a valid National Provider
        Identifier (NPI) who treats patients with sickle cell disease?
      </p>
      <div className="button-container">
        <Button
          white
          onClick={onYes}
          text="Yes"
          analytics={{
            subject: "Meeting Request",
            label: "Request KOL - Step 1/4 - Yes",
            action: "Clicked",
            category: "Internal Links",
          }}
        />
        <Button
          white
          onClick={onNo}
          text="No"
          analytics={{
            subject: "Meeting Request",
            label: "Request KOL - Step 1/4 - No",
            action: "Clicked",
            category: "Internal Links",
          }}
        />
      </div>
    </>
  );
};

export default QuestionSection;
