import React, { FC, PropsWithChildren } from "react";
import { TwoColumn } from "../Flexbox";

import "./styles.scss";

type BenefitsListProps = PropsWithChildren<{}>;

type BenefitsListItemProps = {
  title: string;
  image?: string;
  text;
};

export const BenefitsList: FC<BenefitsListProps> = ({ children }) => (
  <div className="benefits-list">{children}</div>
);

export const BenefitsListItem: FC<BenefitsListItemProps> = ({
  title,
  image,
  text,
}) => {
  return (
    <TwoColumn>
      {image && (
        <img
          role="icon"
          alt=""
          className="benefits-list__image"
          width="72"
          height="72"
          src={image}
        />
      )}
      <div className="benefits-list__item">
        <h3 className="benefits-list__title">{title}</h3>
        <div className="benefits-list__item">{text}</div>
      </div>
    </TwoColumn>
  );
};
